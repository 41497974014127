:root {
    --correct-color: #28a745;
    --partial-color: #ffc107;
    --wrong-color: #dc3545;
    --cb-correct-color: #3CB371;
    --cb-partial-color: #FFD700;
    --cb-wrong-color: #de74ff;
    --dark-color: #010A13;
}

span {
    font-family: Spiegel;
    font-size: 1.2em;
    margin: auto;
}

p, small {
    font-family: Spiegel;
}

.playerCount {
    display: flex;
    justify-content: space-between;
}

.playerCount h5 {
    font-size: 1.1em;
}

.playerCount span {
    font-family: "Beaufort for LOL";
    font-size: 1em;
}

.main {
    padding-bottom: 8em;
}

.breakWord {
    word-spacing: 100vw;
}

.championData {
    border: 0.1em solid var(--dark-color);
    box-shadow: inset 0 0 0.6em var(--dark-color);
    display: flex;
}

.championData:first-child{
    width: 10ch;
}

.correct{
    background-color: var(--correct-color);
}

.partial {
    background-color: var(--partial-color);
}

.incorrect {
    background-color: var(--wrong-color);
}

.cb-correct{
    background-color: var(--cb-correct-color);
}

.cb-partial {
    background-color: var(--cb-partial-color);
}

.cb-incorrect {
    background-color: var(--cb-wrong-color);
}

.incorrect-greater {
    background-image: linear-gradient(var(--correct-color), 60%, var(--wrong-color));
}

.incorrect-less {
    background-image: linear-gradient(var(--wrong-color), 60%, var(--correct-color));
}

.cb-incorrect-greater {
    background-image: linear-gradient(var(--cb-correct-color), 60%, var(--cb-wrong-color));
}

.cb-incorrect-less {
    background-image: linear-gradient(var(--cb-wrong-color), 60%, var(--cb-correct-color));
}

.victory{
    display: flex;
    justify-content: center;
}

.victory p {
    font-size: 1.4em;
}

.victory > .card {
    color: #fff;
    background-image: linear-gradient(#408140, #5cb85c);
}

.victory .smaller {
    font-size: 1.1em;
}

.transparentModal .modal-header {
    border: none;
}

.transparentModal .modal-content {
    background-color: transparent;
    border: none;
}

.regionText {
    white-space: pre;
}

.demo {
    width: 4vw;
    height: 4vw;
    border: 0.1em solid var(--dark-color);
    box-shadow: inset 0 0 0.6em var(--dark-color);
}

#championsImgs {
    max-width: 25vw;
}

#spriteContainer {
    width: 40vw;
    height: auto;
}

#spriteContainer img, #itemContainer img{
    width:100%;
}

#itemContainer {
    width: 30vw;
    height: auto;
}

.darkBtn {
    background-color: #1E2328;
}

.scoreboard {
    width: 75%;
    padding: 3em 3em;
    padding-bottom: 1em;
}

.select img {
    padding: 0.1em;
    margin-top: 0.4em;
    margin-bottom: 0.4em;
}

.select input[type="text"] {
    caret-color: transparent;
}

.select-option {
    display: flex;
    align-items: flex-start;
}

.select-option img {
    height: 40px;
    width: 40px;
    margin-right: 1em;
    margin-bottom: 0em;
    margin-top: 0em;
}
.select-option span {
    flex: 1;
    font-family: "Beaufort for LOL";
}

@media only screen and (min-width: 600px) {

    .container, .container-md, .container-sm {
        max-width: 100vw;
    }

    .scoreboard {
        width: 100%;
        padding: 2em 2em;
    }

    #itemContainer {
        width: 40vw;
        height: auto;
    }
}

@media only screen and (min-width: 768px) {

    #gameTitle, .championData {
        font-size: 0.9em;
    }

    .container {
        padding: 0;
    }

    .championData:first-child{
        max-width: 7.5ch;
    }

    #guess-form {
        width: 45%;
    }

    .col-1, .col-2 {
        padding: 0em;
    }

    .container, .container-md, .container-sm {
        max-width: 90vw;
    }

    .transparentModal .card {
        width: 100%;
    }

    #spriteContainer {
        width: 40vw;
        height: auto;
    }

    #itemContainer {
        width: 40vw;
        height: auto;
    }

    #championsImgs {
        max-width: 20vw;
    }

    .scoreboard {
        width: 90%;
    }
}

@media only screen and (min-width: 992px) {
    .championData:first-child{
        max-width: 7.5ch;
    }

    #gameTitle, .championData {
        font-size: 0.9em;
    }

    #guess-form {
        width: 40%;
    }

    .transparentModal .card {
        width: 75%;
    }

    #spriteContainer {
        width: 40vw;
        height: auto;
    }

    #itemContainer {
        width: 30vw;
        height: auto;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px){
    .championData:first-child{
        max-width: 8.5ch;
    }

    #guess-form {
        width: 35%;
    }

    #gameTitle, .championData {
        font-size: 1em;
    }

    .transparentModal .card {
        width: 75%;
    }

    #spriteContainer {
        width: 40vw;
        height: auto;
    }

    #itemContainer {
        width: 30vw;
        height: auto;
    }

    #championsImgs {
        max-width: 10vw;
    }
}

@media only screen and (min-width: 1400px) {
    .championData:first-child{
        max-width: 10ch;
    }

    #guess-form {
        width: 30%;
    }

    .container, .container-md, .container-sm {
        max-width: 70vw;
    }

    #gameTitle, .championData {
        font-size: 1.1em;
    }

    .transparentModal .card {
        width: 75%;
    }

    #spriteContainer {
        width: 30vw;
        height: auto;
    }

    #itemContainer {
        width: 20vw;
        height: auto;
    }

    #championsImgs {
        max-width: 12vw;
    }
}